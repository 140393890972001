<template>

	<div>
		<div class="block-header">
		</div>

		<div class="container-fluid">
			<div class="row clearfix">
				<div class="col-lg-12">
					<div class="card">
						<div class="body">

							<div>
								<el-button v-if="operation!=='查看'" type="primary" size="medium" style="float: right;"
									@click="submit">
									{{operation}}
								</el-button>
								<el-page-header @back="go(`/project-overseas-list/${page}`)" :content="'驻外招商--'+operation">
								</el-page-header>
							</div>

							<el-form ref="form" label-width="160px" size="small">

								<el-form-item label="填报单位">
									<el-row>
										<el-col :span="10">
											<el-tag type="success">{{form.填报单位}}</el-tag>
										</el-col>
									</el-row>
								</el-form-item>

								<el-row>
									<el-col :span="10">
										<el-form-item label="姓名" required>
											<el-input v-model="form.姓名" placeholder="可填报3-5位外出人员，如:张三 李四"></el-input>
										</el-form-item>
									</el-col>

									<el-col :offset="1" :span="12">
										<el-form-item label="招商领导类型" required>
											<el-radio-group v-model="form.招商领导类型">
												<el-radio-button label="党政主职"></el-radio-button>
                        <el-radio-button label="分管领导"></el-radio-button>
												<el-radio-button label="分局局长"></el-radio-button>
												<el-radio-button label="其它"></el-radio-button>
											</el-radio-group>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="开始时间" required>
											<el-date-picker v-model="form.开始时间" align="left" type="date"
												placeholder="选择日期" :picker-options="pickerOptions" format="yyyy-MM-dd"
												value-format="yyyy-MM-dd">
											</el-date-picker>
										</el-form-item>
									</el-col>

									<el-col :span="10" :offset="1">
										<el-form-item label="结束时间" required>
											<el-date-picker v-model="form.结束时间" align="right" type="date"
												:picker-options="pickerOptions" placeholder="选择日期" format="yyyy-MM-dd"
												value-format="yyyy-MM-dd" @change="datedifference(form.开始时间,form.结束时间)">
											</el-date-picker>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="到达目的地" required>
											<el-input v-model="form.到达目的地" placeholder="可填报多个地名，如:深圳 上海"></el-input>
										</el-form-item>
									</el-col>

									<el-col :span="10" :offset="1">
										<el-form-item label="出访天数(天)" required>
											<el-input-number v-model="form.出访天数" controls-position="right" :min="1">
											</el-input-number>
										</el-form-item>
									</el-col>
								</el-row>

								<el-form-item label="附件" required>
									<el-tag effect="plain" type="danger" style="margin-bottom: 5px;">上传外出招商相关附件图片
									</el-tag>
									<multiple-file-upload v-model="form.附件"></multiple-file-upload>
								</el-form-item>

								<el-row>
									<el-col :span="18">
										<el-form-item label="拜访企业" required>

											<el-table :data="form.items" style="width: 100%" border height="220px">
												<el-table-column type="index" width="50" align="center">
												</el-table-column>
												<el-table-column label="企业名称" align="center" min-width="150">
													<template slot-scope="scope">
														<el-input v-model="scope.row.拜访企业"></el-input>
													</template>
												</el-table-column>
												<el-table-column label="接洽人姓名" align="center">
													<template slot-scope="scope">
														<el-input v-model="scope.row.接洽人姓名"></el-input>
													</template>
												</el-table-column>
												<el-table-column label="接洽人职务" align="center">
													<template slot-scope="scope">
														<el-input v-model="scope.row.接洽人职务"></el-input>
													</template>
												</el-table-column>
												<el-table-column align="center" width="80">
													<template slot="header" slot-scope="scope">
														<el-button type="text" icon="el-icon-plus"
															@click="handelItemsAdd"></el-button>
													</template>
													<template v-slot="scope">
														<el-button type="text" icon="el-icon-delete"
															@click="handelItemsRemove(scope.$index)"></el-button>
													</template>
												</el-table-column>
											</el-table>

										</el-form-item>
									</el-col>
								</el-row>

								<el-divider>非必填</el-divider>

								<el-row>
									<el-col :span="18">
										<el-form-item label="拜访企业类型">
											<el-radio-group v-model="form.拜访企业类型">
												<el-radio-button label="一般企业"></el-radio-button>
												<el-radio-button label="大型企业"></el-radio-button>
											</el-radio-group>
										</el-form-item>

									</el-col>
								</el-row>

								<el-row>
									<el-col :span="18">
										<el-form-item label="衔接领导赴外考察项目">

											<el-table :data="form.衔接领导赴外考察项目" style="width: 100%" border height="220px">
												<el-table-column type="index" width="50" align="center">
												</el-table-column>
												<el-table-column label="外出领导姓名" align="center">
													<template slot-scope="scope">
														<el-input v-model="scope.row.外出领导姓名"></el-input>
													</template>
												</el-table-column>
												<el-table-column label="职务" align="center">
													<template slot-scope="scope">
														<el-input v-model="scope.row.职务"></el-input>
													</template>
												</el-table-column>
												<el-table-column label="考察企业" align="center" min-width="150">
													<template slot-scope="scope">
														<el-input v-model="scope.row.考察企业"></el-input>
													</template>
												</el-table-column>
												<el-table-column align="center" width="80">
													<template slot="header" slot-scope="scope">
														<el-button type="text" icon="el-icon-plus"
															@click="form.衔接领导赴外考察项目.push({外出领导姓名:'',职务:'',考察企业:''})">
														</el-button>
													</template>
													<template v-slot="scope">
														<el-button type="text" icon="el-icon-delete"
															@click="form.衔接领导赴外考察项目.splice(scope.$index, 1)">
														</el-button>
													</template>
												</el-table-column>
											</el-table>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="备注">
											<el-input v-model="form.备注" type="textarea"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

								<el-form-item v-if="operation!=='审批' && operation!=='填报'" label="审批信息">
									<el-form-item>
										{{form.审批状态+" "+	(form.审批意见?form.审批意见:'')}}
									</el-form-item>
								</el-form-item>


								<el-row v-if="operation==='审批'">
									<el-col :span="18">
										<el-form-item label="审批">
											<el-radio-group v-model="form.审批状态">
												<el-radio-button label="待审批"></el-radio-button>
												<el-radio-button label="审批通过"></el-radio-button>
												<el-radio-button label="审批未通过"></el-radio-button>
											</el-radio-group>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row v-if="operation==='审批'">
									<el-col :span="18">
										<el-form-item label="审批意见">
											<el-input v-model="form.审批意见" :autosize="{ minRows: 2, maxRows: 4}"
												type="textarea"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

							</el-form>


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import projectOverseasController from '../controller/projectOverseasController.js'
	import MultipleFileUpload from '../components/MultipleFileUpload.vue';
	export default {
		components: {
			MultipleFileUpload
		},
		data() {
			return {
				operation: '',
				id: '',
        page:1,
				isApproval: false,
				form: {
					附件: [],
					items: [],
					衔接领导赴外考察项目: [],
				},
				user: {},
				pickerOptions: {
					disabledDate(time) {
						let curDate = (new Date()).getTime();
						let three = 30 * 24 * 3600 * 1000;
						let threeMonths = curDate - three;
						return time.getTime() > Date.now() || time.getTime() < threeMonths;;
					}
				}
			}
		},
		created() {
			this.id = this.getParam('id');
			this.isApproval = this.getParam('approval') === 'approval'
			this.user = JSON.parse(sessionStorage.getItem('user'))
      this.page = this.getParam('page');


			if (this.isApproval) {
				this.operation = '审批'
				this.initForm();
			} else if (this.id) {
				this.operation = '修改'
				this.initForm();
			} else {
				this.operation = '填报'
				this.form.填报单位 = this.user.dept_name
			}

		},
		methods: {
			initForm() {
				projectOverseasController.get({
					unique: this.id
				}).then(res => {
					this.form = res.data;
					if (this.form.modified.status < 0) {
						this.operation = '查看';
					}
				})
			},
			submit() {
				if (!this.validate())
					return;

				let obj = this.deepClone(this.form)


				if (this.isApproval) {
					projectOverseasController.approval(obj).then(res => {
						this.go(`/project-overseas-list/${this.page}`)
					})
				} else if (obj.id) {
					projectOverseasController.put(obj).then(res => {
						this.go(`/project-overseas-list/${this.page}`)
					})
				} else {
					projectOverseasController.post(obj).then(res => {
						this.go(`/project-overseas-list/${this.page}`)
					})
				}

			},
			datedifference(sDate1, sDate2) { //sDate1和sDate2是2006-12-18格式  
				var dateSpan,
					tempDate,
					iDays;
				sDate1 = Date.parse(sDate1);
				sDate2 = Date.parse(sDate2);
				dateSpan = sDate2 - sDate1;
				dateSpan = Math.abs(dateSpan);
				iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
				this.form.出访天数 = iDays + 1;
			},
			handelItemsAdd() {
				this.form.items.push({
					拜访企业: '',
					接洽人姓名: '',
					接洽人职务: '',
				})
			},
			handelItemsRemove(index) {
				this.form.items.splice(index, 1)
			},
			validate() {


				if (!this.form.姓名) {
					this.$message.error('请填写姓名')
					return false;
				}

				if (!this.form.开始时间) {
					this.$message.error('请填写开始时间')
					return false;
				}

				if (!this.form.结束时间) {
					this.$message.error('请填写结束时间')
					return false;
				}

				if (!this.form.招商领导类型) {
					this.$message.error('请填写招商领导类型')
					return false;
				}

				if (!this.form.到达目的地) {
					this.$message.error('请填写到达目的地')
					return false;
				}

				if (!this.form.出访天数) {
					this.$message.error('请填写出访天数')
					return false;
				}

				if (this.form.附件.length < 1) {
					this.$message.error('请上传相关附件')
					return false;
				}

				if (this.form.items.length < 1) {
					this.$message.error('请填写拜访企业')
					return false;
				}

				return true;

			}
		}

	}
</script>

<style scoped>
	.el-page-header {
		margin-bottom: 40px;
	}

	.el-date-picker {
		cursor: pointer;
	}

	.el-table thead {
		color: white;
		font-size: 12px;
	}

	.el-form-item__content {
		line-height: 0px;
	}

	.el-table th,
	.el-table tr {
		background-color: skyblue;
	}
</style>
